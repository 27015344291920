import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from "gatsby";
import { BannerAlertBox } from '@entur/alert';
import { Link } from 'docz';
import { Link as LinkText } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import { TypographyDisplay } from '~/components/TypographyDisplay';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
query typografiQuery {
  graphics: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/grafiske_elementer"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <ImageDisplay fluidSource={props.data.graphics.images.find(image => image.name === "Typo_eksempel").childImageSharp.fluid} alt="Eksempeltekst som viser fram Entur sin font: Nationale" preset="full-width-image" mdxType="ImageDisplay" />
    <h2 {...{
      "id": "primærskrifttype"
    }}>{`Primærskrifttype`}</h2>
    <p>{`Primærskrifttypen til Entur heter Nationale. Nationale er en moderne og geometrisk skrifttype med meget god lesbarhert både digitalt og på trykk. Nationale brukes til både overskrifter, ingress og i løpende brødtekst. `}</p>
    <p>{`Skrifttypen tilbys i mange vekter (10 stk, inkludert kursiver). Ved å kombinere ulike vekter på en god måte, kan vi skape et tydelig hierarki som gjør det enklere for leseren å forstå tekstene våre. Særegenheten i typografien ligger i måten vi bruker den på. `}</p>
    <p>{`I digitale flater skal skrifttypen kun brukes i to vekter, medium for ingress og løpende brødtekst og demibold for overskrifter og utheving av tekst.`}</p>
    <p>{`Nationale er lisensbelagt og brukes dermed normalt kun av designere. Derfor skal de aller fleste bruke `}<a parentName="p" {...{
        "href": "#st%C3%B8ttefont"
      }}>{`støttefonten Arial`}</a>{` som finnes på alle PCer. `}</p>
    <p>{`Les mer om hvordan du tar i bruk skrifttypen i produktutvikling på `}<a parentName="p" {...{
        "href": "/komponenter/ressurser/typography"
      }}>{`siden om typografi-komponentene våre`}</a>{`. `}</p>
    <BannerAlertBox variant="information" title="Typografi som grafisk element" closable={false} style={{
      marginBottom: '1em'
    }} mdxType="BannerAlertBox">
  I identiteten brukes typografi også som et bærende grafisk element for å
  understøtte kontrastene og mangfoldigheten.{' '}
  <LinkText as={Link} to="/identitet/vektoykassen/grafiske-elementer" mdxType="LinkText">
    Les mer om dette og se eksempler på infosiden om grafiske elementer.
  </LinkText>
    </BannerAlertBox>
    <h3 {...{
      "id": "fontvarianter"
    }}>{`Fontvarianter`}</h3>
    <TypographyDisplay weight={300} mdxType="TypographyDisplay" />
    <TypographyDisplay weight={400} mdxType="TypographyDisplay" />
    <TypographyDisplay weight={500} mdxType="TypographyDisplay" />
    <TypographyDisplay weight={600} mdxType="TypographyDisplay" />
    <h3 {...{
      "id": "lisens"
    }}>{`Lisens`}</h3>
    <p>{`For å installere Nationale på din datamaskin, trenger du en lisens.
Entur har selv noen lisenser, men alle samarbeidspartnere må ha egen lisens.
Fonten kan kjøpes direkte på `}<a parentName="p" {...{
        "href": "https://playtype.com/index.php?q=font/nationale"
      }}>{`Playtype sine nettsider`}</a>{`.`}</p>
    <BannerAlertBox variant="information" title="Trenger du lisens?" closable={false} mdxType="BannerAlertBox">
  Ta kontakt med markedsavdeling i Entur for spørsmål om tilgang til Nationale-skrifttypen.
    </BannerAlertBox>
    <h2 {...{
      "id": "støttefont"
    }}>{`Støttefont`}</h2>
    <p>{`Nationale er lisensbelagt, og brukes dermed normalt kun av designere.
Derfor skal de aller fleste bruke støttefonten Arial, som er en systemfont som støttes av alle operativsystemer.
Som støttefont brukes systemfonten Arial i f.eks Office-maler og Power Point slik at alle skal kunne ha tilgang til samme font.`}</p>
    <h3 {...{
      "id": "fontvarianter-1"
    }}>{`Fontvarianter`}</h3>
    <TypographyDisplay weight={400} fontFamily='Arial' mdxType="TypographyDisplay" />
    <TypographyDisplay weight={700} fontFamily='Arial' mdxType="TypographyDisplay" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      